import React from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore';

// project imports
import { firebaseDB } from 'index';
import { ImportantNoticeIcon, NoticeIcon } from 'components';

// material-ui
import { Box, ButtonBase, Card, CircularProgress, Stack, Typography } from '@mui/material';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';

/**
 * 홈 > 공지사항
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
const ShopNotice = () => {
  const navigate = useNavigate();

  // 공지사항 정보
  const [notice, setNotice] = React.useState({ loaded: false, error: false, data: null });

  React.useLayoutEffect(() => {
    getNotice();
  }, []);

  // 공지사항 조회
  const getNotice = async () => {
    const noticeSnapshot = await getDocs(
      query(collection(firebaseDB, 'notice'), where('important', '==', true), orderBy('created_at', 'desc'), limit(1))
    ).catch((error) => ({ error }));

    // 공지사항 조회 중 오류 발생
    if (noticeSnapshot.error) {
      setNotice({ loaded: true, error: true, data: null });
      console.error('[ShopNotice][getNotice] 공지사항 조회 중 오류 발생 > ', noticeSnapshot.error);
      return undefined;
    }

    const { docs, empty } = noticeSnapshot;

    // 공지사항 없음
    if (empty) {
      console.warn('[ShopNotice][getNotice] 등록된 입점샵 중요 공지사항 없음');
      setNotice({ loaded: true, error: false, data: null });
      return undefined;
    }

    const _notice = docs[0].data();

    setNotice({ loaded: true, error: false, data: _notice });
  };

  // render
  return (
    <Card sx={{ mt: '16px', boxShadow: '0px 2px 6px #0000000A', py: '10px', px: '12px' }}>
      {notice.loaded && !notice.error && notice.data && (
        <Box onClick={() => navigate(`/management/notice/${notice?.data?._id}`)}>
          <Stack direction="row" alignItems="center" spacing="8px">
            <NoticeIcon />
            <Box display="flex" width="calc(100% - 25px - 8px)" height="100%" alignItems="center">
              <Box width="13px" height="13px" display="flex" alignItems="center">
                <ImportantNoticeIcon mr="1px" />
              </Box>

              <Typography variant="body2" noWrap>
                {notice?.data?.title}
              </Typography>
            </Box>
          </Stack>
        </Box>
      )}

      {/* loading */}
      {!notice.loaded && (
        <Box sx={{ height: 25, textAlign: 'center' }}>
          <CircularProgress size={20} />
        </Box>
      )}

      {/* 공지사항 오류 */}
      {notice.loaded && notice.error && (
        <Box sx={{ height: 45, my: '-10px', mx: '-12px' }}>
          <ButtonBase sx={{ width: '100%', height: '100%' }} onClick={getNotice}>
            <ReplayOutlinedIcon sx={{ mr: 1 }} />
            재시도
          </ButtonBase>
        </Box>
      )}
    </Card>
  );
};

export default ShopNotice;
