import PropTypes from 'prop-types';

/**
 * 핸드폰번호 마스킹
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function MskPhone(num) {
  let formatNum = '';
  if (num.length === 11) {
    formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3');
  } else if (num.length === 8) {
    formatNum = num.replace(/(\d{4})(\d{4})/, '$1-$2');
  } else if (num.indexOf('02') === 0) {
    formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-****-$3');
  } else {
    formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3');
  }

  return formatNum;
}

export default MskPhone;

MskPhone.propTypes = {
  num: PropTypes.string.isRequired
};
