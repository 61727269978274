import React from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

// import mui
import { Box, Typography } from '@mui/material';

// import components
import AlarmDrawer from './AlarmDrawer';
import { AlarmIcon } from 'components/icons';

// import store
import { useDispatch, useSelector } from 'store';
import { closeAlarmDrawer, openAlarmDrawer } from 'store/slices/alarm';

// import firebase
import { getFirestore } from '../../../utils/firebase-tools';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

/**
 * 알람버튼
 * @constructor
 *
 * @authors 최효근<hkchoi@wineone.io>
 */

function AlarmButton() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  // 알람 store
  const alarm = useSelector((state) => state.alarm);

  // 알람갯수
  const [alarmCount, setAlarmCount] = React.useState(null);

  // 알람갯수 가지고오기
  const fetchAlarmCount = async () => {
    const { currentUser } = getAuth();
    if (currentUser) {
      const { uid } = currentUser;
      const queryOpts = [collection(getFirestore(), `member/${uid}/user_alarm`), where('remove', '==', false)];
      const alarmlistSnapshot = await getDocs(query(...queryOpts)).catch((error) => ({ error }));
      setAlarmCount(alarmlistSnapshot.size);
    }
  };

  React.useEffect(() => {
    fetchAlarmCount();
  }, [alarm.open]);

  React.useEffect(() => {
    const alarmDrawerOpen = isAlarmOpen();

    if (alarmDrawerOpen) {
      dispatch(openAlarmDrawer());
    } else {
      dispatch(closeAlarmDrawer());
    }
  }, [searchParams.get('alarm')]);

  // 알람열림상태
  function isAlarmOpen() {
    return searchParams.get('alarm') === '1';
  }

  // 뒤로가기
  const handleClose = () => {
    navigate(-1);
  };

  // 알림 Drawer 컴포넌트
  const alarmDrawerComponent = React.useMemo(() => {
    return <AlarmDrawer open={alarm.open} onClose={handleClose} />;
  }, [alarm.open]);

  return (
    <>
      <Box
        display="flex"
        position="relative"
        onClick={() => {
          const search = createSearchParams();
          searchParams.forEach((val, key) => {
            search.set(key, val);
          });

          if (isAlarmOpen()) {
            search.delete('alarm');
          } else {
            search.set('alarm', 1);
          }
          navigate({ search: search.toString() });
        }}
      >
        <AlarmIcon />

        {/* 알림 숫자 */}
        {alarmCount > 0 && (
          <Box
            width="18px"
            height="18px"
            padding="5px"
            bgcolor="#BAE151"
            borderRadius="50%"
            position="absolute"
            top="-6px"
            right="-10px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {alarmCount > 99 ? (
              <Typography fontSize="8px" color="#000" fontWeight="bold">
                99
                <Typography fontSize="6px" fontWeight="bold" sx={{ verticalAlign: 'super' }} component="span">
                  +
                </Typography>
              </Typography>
            ) : (
              <Typography fontSize="10px" color="#000" fontWeight="bold">
                {alarmCount}
              </Typography>
            )}
          </Box>
        )}
      </Box>
      {/* 알림 Drawer */}
      {alarmDrawerComponent}
    </>
  );
}

export default AlarmButton;
