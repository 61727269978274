import React from 'react';
import PropTypes from 'prop-types';
import { doc, getDoc } from 'firebase/firestore';

// project imports
import { firebaseDB } from 'index';
import useAuth from 'hooks/useAuth';
import { WoAlert } from 'utils/kmwine-alerts';
import { updateVendorV } from 'services/VendorService';
import SpaceBetweenBox from 'components/SpaceBetweenBox';
import { WineBottleLoadingLottie } from 'components/spinner';

// material-ui
import { Backdrop, Box, ButtonBase, Card, CircularProgress, Tab, Tabs, Typography } from '@mui/material';
import { common } from '@mui/material/colors';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
}

// 탭 가로 길이
const TAB_WIDTH = 210;
// 탭 세로 길이
const TAB_HEIGHT = 33;
// 탭 borderRadius
const TAB_BORDER_RADIUS = 22;

/**
 * 홈 > 입점샵 영업 상태
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
const StopInfo = ({ waitCounts, vendorStopState }) => {
  const { user } = useAuth();

  // loading
  const [loading, setLoading] = React.useState(false);
  // 샵 정보
  const [vendor, setVendor] = React.useState({ loaded: false, error: false, data: null });
  // 영업 임시 중지 / 영업 중 탭
  const [tabIndex, setTabIndex] = React.useState('open');

  React.useEffect(() => {
    if (!user?.vendorId) return;

    getVendor();
  }, [user]);

  // 입점샵 정보 조회
  const getVendor = async () => {
    setVendor({ loaded: false, error: false, data: null });
    const { vendorId } = user;

    const vendorSnapshot = await getDoc(doc(firebaseDB, 'vendor', vendorId)).catch((error) => ({ error }));

    // 입점샵 정보 조회 중 오류 발생
    if (vendorSnapshot.error) {
      setVendor({ loaded: true, error: true, data: null });
      console.error('[StopInfo][getVendor] 입점샵 정보 조회 중 오류 발생 > ', vendorSnapshot.error);
      return undefined;
    }

    // 입접샵 정보가 존재하지 않음
    if (!vendorSnapshot.exists()) {
      setVendor({ loaded: true, error: true, data: null });
      console.error('[StopInfo][getVendor] 입점샵 정보가 존재하지 않음 > ', vendorSnapshot);
      return undefined;
    }

    const _vendor = vendorSnapshot.data();

    const closed = _vendor.closed === 'true' || _vendor.closed === true;

    setVendor({ loaded: true, error: false, data: _vendor });
    setTabIndex(_vendor.closed === 'true' || _vendor.closed === true ? 'closed' : 'open');
    vendorStopState(closed);
  };

  // 샵 영업상태 변경
  const changeStop = async (val) => {
    const { REQ_COUNT } = waitCounts;

    if (val === 'closed' && REQ_COUNT > 0) {
      WoAlert.fire('', '주문 승인 대기건이 있습니다.', 'error');
      return false;
    }

    let woAlertConfirmed;

    // 임시 중지 상태로 변경 시 컨펌 모달
    if (val === 'closed') {
      woAlertConfirmed = await WoAlert.fire({
        html: `<div style='line-height: 1.5rem;'>[영업 임시 중지] 상태로 설정되어 있는 동안에는 앱으로 주문을 받을 수 없습니다.<br /><br />영업상태를 변경할까요?</div>`,
        icon: 'question',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        customClass: {
          confirmButton: 'max-50',
          cancelButton: 'max-50'
        }
      });
    }
    // 임시 중지 해제 시 컨펌 모달
    else {
      woAlertConfirmed = await WoAlert.fire({
        html: `<div style='line-height: 1.5rem;'>[영업 중]으로 변경 시 다시 앱으로 주문을 받을 수 있는 상태가 됩니다.<br /><br />영업상태를 변경할까요?</div>`,
        icon: 'question',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        customClass: {
          confirmButton: 'max-50',
          cancelButton: 'max-50'
        }
      });
    }

    if (!woAlertConfirmed.isConfirmed) {
      return false;
    }

    setLoading(true);

    const {
      data: { _id, shop, settlement, biz }
    } = vendor;

    const body = {
      id: _id,
      shop,
      settlement,
      biz: {
        location_exp: {
          lat: biz.location_exp._lat,
          lon: biz.location_exp._long
        }
      },
      closed: val === 'closed' && true
    };

    const result = await updateVendorV(body)
      .catch((error) => ({ error }))
      .finally(() => setLoading(false));

    if (result.error) {
      console.error('[StopInfo][changeStop] 샵 영업상태 변경 중 오류 발생 > ', result.error);
      WoAlert.fire('', '영업 상태 변경 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요.', 'error');
      return undefined;
    }

    const { code, msg } = result.result;

    // 잘못된 Response값이 넘어옴
    if (code !== 0) {
      console.error('[StopInfo][changeStop] 샵 영업상태 변경 중 오류 발생 > ', msg);
      WoAlert.fire('', '영업 상태 변경 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요.', 'error');
      return undefined;
    }

    getVendor();
  };

  // render
  return (
    <Box>
      <Backdrop open={loading} sx={{ zIndex: 1211 }}>
        <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <WineBottleLoadingLottie />
          <Typography color={common.white}>샵 정보를 수정하고 있습니다.</Typography>
        </Box>
      </Backdrop>

      <Card sx={{ mt: '20px', boxShadow: '0px 2px 6px #0000000A', py: '16px', pl: '16px', pr: '12px' }}>
        {!vendor.error ? (
          <SpaceBetweenBox>
            <Typography sx={{ fontSize: '12px', color: '#333333CC', letterSpacing: '-0.12px' }}>영업 상태</Typography>
            {vendor.loaded ? (
              <Tabs
                value={tabIndex}
                // onChange={(e, v) => setTabIndex(v)}
                onChange={(e, v) => changeStop(v)}
                indicatorColor="primary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="와인 상세정보 탭"
                sx={{
                  width: TAB_WIDTH,
                  height: TAB_HEIGHT,
                  minHeight: TAB_HEIGHT,
                  backgroundColor: '#F4F0F8',
                  borderRadius: '22px',
                  '& .MuiTabs-flexContainer': { height: '100%' },
                  '& .MuiButtonBase-root': {
                    height: '100%',
                    minHeight: '100%',
                    zIndex: 1,
                    borderRadius: TAB_BORDER_RADIUS,
                    fontSize: '12px'
                  },
                  '& .Mui-selected': { color: '#DEFD9A', fontWeight: 800 }
                }}
                TabIndicatorProps={{
                  sx: {
                    height: '100%',
                    backgroundColor: '#220348',
                    borderRadius: TAB_BORDER_RADIUS
                  }
                }}
              >
                <Tab label="영업 임시 중지" {...a11yProps(0)} value="closed" />
                <Tab label="영업 중" {...a11yProps(1)} value="open" sx={{ color: '#000' }} />
              </Tabs>
            ) : (
              <CircularProgress size={33} />
            )}
          </SpaceBetweenBox>
        ) : (
          <Box sx={{ height: 65, my: '-16px', ml: '-16px', mr: '-12px' }}>
            <ButtonBase sx={{ width: '100%', height: '100%' }} onClick={() => getVendor()}>
              <ReplayOutlinedIcon sx={{ mr: 1 }} />
              재시도
            </ButtonBase>
          </Box>
        )}
      </Card>
    </Box>
  );
};

export default StopInfo;

StopInfo.propTypes = {
  waitCounts: PropTypes.object,
  vendorStopState: PropTypes.func
};
