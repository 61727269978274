import React from 'react';
import * as yup from 'yup';
import moment from 'moment';
import { httpsCallable } from 'firebase/functions';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';

// import project
import { useAuth } from '../../hooks';
import { WoAlert } from '../../utils/kmwine-alerts';
import { CLO_CODE } from 'config';
import { getFirebaseFunctions } from 'utils/firebase-tools';
import { getVendorDayoffList, createVendorDayoff, deleteVendorDayoff, checkPickupOrder } from '../../services/VendorService';
import { ko } from 'date-fns/locale';

// material-ui
import {
  Grid,
  Typography,
  Box,
  InputLabel,
  Stack,
  FormControl,
  TextField,
  Button,
  TableContainer,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Table,
  CircularProgress
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

/**
 * 샵 임시휴무일
 *
 * @authors 최효근<hkchoi@wineone.io>
 */
const VendorDayoff = () => {
  const location = useLocation();
  const { user } = useAuth();
  const sendCloError = httpsCallable(getFirebaseFunctions(), 'call-cdm-clo-error');

  // 로딩
  const [loading, setLoading] = React.useState(false);

  // 휴무일 리스트
  const [dayoffList, setDayoffList] = React.useState([]);

  // 날짜 선택이 안되는 날
  const [disabledDate, setDisabledDate] = React.useState(null);

  const validationSchema = () => {
    const validationObject = {
      startDate: yup.date().required(<span style={{ color: 'red' }}>필수 항목입니다</span>),
      endDate: yup.date().required(<span style={{ color: 'red' }}>필수 항목입니다</span>),
      label: yup.string().required('필수 항목입니다.').min(2, '최소 2자이상을 입력해주세요').max(8, '최대 8자까지 가능합니다'),
      memo: yup.string()
    };

    return yup.object(validationObject);
  };

  const formik = useFormik({
    initialValues: {
      startDate: '',
      endDate: '',
      label: '',
      memo: ''
    },
    validationSchema,
    onSubmit: async () => {
      // 픽업주문 리스트 조회
      const pickupList = await getPickUpList();

      WoAlert.fire({
        title: '휴무일 저장',
        icon: 'question',
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: '취소',
        cancelButtonTextColor: '#fff',
        html: `<div style="line-height: 1rem; text-align: center">
        ${pickupList.length > 0 ? `<p>픽업주문이 존재합니다.</p>` : ''}
        ${pickupList.map((item) => `<div><span>${item.pickup_date} - ${item.order_count}건</span></div>`).join('')}
        <p>휴무일을 저장하시겠습니까?</p>
        </div>`
      }).then(async (result) => {
        if (result.isConfirmed) {
          createVendorDayOff();
        }
      });
    }
  });

  // 휴무일 리스트 조회
  const getVendorDayoff = async () => {
    setLoading(true);
    const result = await getVendorDayoffList({ vendor_id: user.vendorId }).catch((error) => {
      console.error(error);
      sendCloError({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `[1KMWINE] 휴무일 리스트 조회 중 오류`,
        msg: `${JSON.stringify(error)}`,
        which: `${location.pathname}${location.search}`,
        param: {
          uid: user?._id
        }
      })
        .then(console.log)
        .catch(console.error);

      WoAlert.fire(
        '휴무일 리스트 조회 실패',
        `휴무일 리스트 조회 중 오류가 발생했습니다.<br />[${error}]<br /><br />잠시 후 다시 시도해주세요.`,
        'error'
      );
    });

    // 날짜 형식 변경
    const resultData = result.map((item) => ({
      ...item,
      off_date: moment(item.off_date).format('YYYY-MM-DD')
    }));

    // 날짜 선택이 안되는 날짜 설정
    const invalidDate = result.map((item) => moment(item.off_date).format('YYYY-MM-DD'));
    setDisabledDate(invalidDate);

    // group_id를 기준으로 그룹핑
    const processedData = resultData.reduce((acc, item) => {
      const existingGroup = acc.find((group) => group.group_id === item.group_id);

      if (!existingGroup) {
        acc.push({
          group_id: item.group_id,
          start_dt: item.off_date,
          end_dt: item.off_date,
          off_label: item.off_label,
          memo: item.memo
        });
      } else {
        existingGroup.start_dt = existingGroup.start_dt < item.off_date ? existingGroup.start_dt : item.off_date;
        existingGroup.end_dt = existingGroup.end_dt > item.off_date ? existingGroup.end_dt : item.off_date;
      }

      return acc;
    }, []);

    // start_dt를 기준으로 정렬
    processedData.sort((a, b) => new Date(a.start_dt) - new Date(b.start_dt));

    setDayoffList(processedData);
    setLoading(false);
  };

  React.useEffect(() => {
    getVendorDayoff();
  }, []);

  // 휴무일 생성
  const createVendorDayOff = async () => {
    setLoading(true);
    const result = await createVendorDayoff({
      vendor_id: user.vendorId,
      start_dt: moment(formik.values.startDate).format('YYYY-MM-DD'),
      end_dt: moment(formik.values.endDate).format('YYYY-MM-DD'),
      label: formik.values.label,
      memo: formik.values.memo
    }).catch((error) => {
      console.error(error);
      sendCloError({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `[1KMWINE] 휴무일 생성 중 오류`,
        msg: `${JSON.stringify(error)}`,
        which: `${location.pathname}${location.search}`,
        param: {
          uid: user?._id
        }
      })
        .then(console.log)
        .catch(console.error);

      WoAlert.fire(
        '휴무일 생성 실패',
        `휴무일 생성 중 오류가 발생했습니다.<br />[${error}]<br /><br />잠시 후 다시 시도해주세요.`,
        'error'
      );
    });

    if (result) {
      WoAlert.fire({
        title: '휴무일 생성 완료',
        icon: 'success',
        allowOutsideClick: false,
        html: `<div style='line-height: 1.5rem; text-align: center'>휴무일이 생성되었습니다.</div>`
      });
      formik.resetForm();
      getVendorDayoff();
    } else {
      WoAlert.fire('휴무일 생성 실패', `휴무일 생성 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요.`, 'error');
    }
  };

  // 휴무일 삭제
  const deleteVendorDayOff = async (group_id) => {
    WoAlert.fire({
      title: '휴무일 삭제',
      icon: 'warning',
      allowOutsideClick: false,
      showCancelButton: true,
      cancelButtonText: '취소',
      cancelButtonTextColor: '#fff',
      html: `<div style='line-height: 1.5rem; text-align: center'>휴무일을 삭제하시겠습니까?</div>`
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        await deleteVendorDayoff({
          vendor_id: user.vendorId,
          group_id
        }).catch((error) => {
          console.error(error);
          sendCloError({
            code: CLO_CODE.UNEXPECTED_ERROR,
            title: `[1KMWINE] 휴무일 삭제 중 오류`,
            msg: `${JSON.stringify(error)}`,
            which: `${location.pathname}${location.search}`,
            param: {
              uid: user?._id
            }
          })
            .then(console.log)
            .catch(console.error);
          WoAlert.fire('휴무일 삭제 실패', `휴무일 삭제 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요.`, 'error');
        });
        getVendorDayoff();
      }
    });
  };

  // 휴무기간 중 픽업주문 data 조회
  const getPickUpList = async () => {
    const result = await checkPickupOrder({
      vendor_id: user.vendorId,
      start_dt: moment(formik.values.startDate).format('YYYY-MM-DD'),
      end_dt: moment(formik.values.endDate).format('YYYY-MM-DD')
    }).catch((error) => {
      console.error(error);
      sendCloError({
        code: CLO_CODE.UNEXPECTED_ERROR,
        title: `[1KMWINE] 픽업주문 data 조회 중 오류`,
        msg: `${JSON.stringify(error)}`,
        which: `${location.pathname}${location.search}`,
        param: {
          uid: user?._id
        }
      })
        .then(console.log)
        .catch(console.error);

      WoAlert.fire(
        '픽업주문 data 조회 실패',
        `픽업주문 data 조회 중 오류가 발생했습니다.<br />[${error}]<br /><br />잠시 후 다시 시도해주세요.`,
        'error'
      );
    });

    return result;
  };

  return (
    <Grid container>
      {loading && (
        <Box display="flex" justifyContent="center" width="100%" mt="50px">
          <CircularProgress />
        </Box>
      )}
      {!loading && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            console.log(formik.values);
            formik.handleSubmit();
          }}
        >
          <Grid item xs={12} mt="30px">
            <InputLabel sx={{ marginBottom: '20px' }}>휴무일 생성</InputLabel>
            <LocalizationProvider locale={ko} dateAdapter={AdapterDateFns}>
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <FormControl>
                  <DatePicker
                    inputFormat={'yyyy-MM-dd'}
                    views={['year', 'month', 'day']}
                    label="시작일"
                    showToolbar={false}
                    value={formik.values.startDate === '' ? null : formik.values.startDate}
                    mask="____-__-__"
                    name="startDate"
                    disabled={user.vendor === null}
                    shouldDisableDate={(date) => {
                      if (disabledDate?.includes(moment(date).format('YYYY-MM-DD'))) {
                        return true;
                      }
                    }}
                    minDate={moment().toDate()}
                    sx={{
                      '.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                        borderColor: `${formik.touched.startDate && Boolean(formik.errors.startDate) ? '#f44336' : 'rgba(0, 0, 0, 0.38)'}`
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        inputProps={{ ...params.inputProps, readOnly: true }}
                        id="startDate"
                        name="startDate"
                        onBlur={formik.handleBlur}
                        error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                        helperText={formik.touched.startDate && formik.errors.startDate}
                      />
                    )}
                    onChange={(newValue) => {
                      formik.setFieldValue('endDate', '');
                      formik.setFieldValue('startDate', newValue);
                    }}
                  />
                </FormControl>
                <Typography>~</Typography>
                <FormControl>
                  <DatePicker
                    inputFormat={'yyyy-MM-dd'}
                    views={['year', 'month', 'day']}
                    label="종료일"
                    mask="____-__-__"
                    showToolbar={false}
                    name="endDate"
                    value={formik.values.endDate === '' ? null : formik.values.endDate}
                    disabled={formik.values.startDate === ''}
                    shouldDisableDate={(date) => {
                      if (disabledDate?.includes(moment(date).format('YYYY-MM-DD'))) {
                        return true;
                      }
                    }}
                    sx={{
                      '.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                        borderColor: `${formik.touched.endDate && Boolean(formik.errors.endDate) ? '#f44336' : 'rgba(0, 0, 0, 0.38)'}`
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{ ...params.inputProps, readOnly: true }}
                        size="small"
                        name="endDate"
                        onBlur={formik.handleBlur}
                        error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                        helperText={formik.touched.endDate && formik.errors.endDate}
                      />
                    )}
                    minDate={formik.values.startDate === '' ? moment().toDate() : moment(formik.values.startDate).toDate()}
                    onChange={(newValue) => {
                      formik.setFieldValue('endDate', newValue);
                    }}
                  />
                </FormControl>
              </Stack>
            </LocalizationProvider>
            <Box marginTop="20px">
              <TextField
                fullWidth
                size="small"
                id="label"
                name="label"
                label="임시휴무명 *"
                placeholder="휴무일명을 입력해주세요."
                value={formik.values.label}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.label && Boolean(formik.errors.label)}
                helperText={formik.touched.label && formik.errors.label}
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 8 }}
              />
            </Box>
          </Grid>
          <Box mt="10px">
            <Button type="submit" fullWidth variant="contained">
              등록
            </Button>
          </Box>
        </form>
      )}
      <Grid mt="20px" item xs={12}>
        <TableContainer sx={{ maxWidth: '500px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">휴무일명</TableCell>
                <TableCell align="center">휴무일</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                dayoffList.length > 0 &&
                dayoffList.map((item) => (
                  <TableRow key={item.group_id}>
                    <TableCell align="center">{item.off_label}</TableCell>
                    <TableCell align="center" sx={{ paddingX: '0px' }}>
                      {item.start_dt === item.end_dt ? (
                        item.start_dt
                      ) : (
                        <Box>
                          <Typography fontSize="13px">{item.start_dt}</Typography>
                          <Typography>~</Typography>
                          <Typography fontSize="13px">{item.end_dt}</Typography>
                        </Box>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          deleteVendorDayOff(item.group_id);
                        }}
                      >
                        삭제
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {dayoffList.length === 0 && (
          <Box width="100%" height="200px" display="flex" justifyContent="left" alignItems="center">
            <Typography marginLeft="100px"> 등록된 휴무일이 없습니다</Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default VendorDayoff;
