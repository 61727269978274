import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

// material-ui
import { Box, Button, ButtonBase, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

// project imports
import * as OrderService from 'services/OrderService';
import LoadingLayout from 'components/loading/LoadingLayout';
import { WoAlert } from 'utils/kmwine-alerts';
import { httpsCallable } from 'firebase/functions';
import { getFirebaseFunctions } from 'utils/firebase-tools';
import { CLO_CODE } from 'config';

/**
 * 주문 상태가 픽업 대기(REQ_OK)만 접근 가능함
 * 주문 픽업대기 컴포넌트
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function ReqOkAction({ order, reloadOrder }) {
  const location = useLocation();

  // 로딩
  const [loading, setLoading] = React.useState(false);
  // 픽업완료 컨펌 모달
  const [pickOkModal, setPickOkModal] = React.useState(false);

  // 픽업 완료 처리
  const pickOkApiCall = async () => {
    console.log(`[픽업완료] 주문번호(${order.oid}) 픽업 완료 요청`);
    setLoading(true);

    const body = {
      oid: order.oid
    };

    const result = await OrderService.orderPickOk(body)
      .catch((error) => ({ error }))
      .finally(() => {
        setPickOkModal(false);
        setLoading(false);
      });

    // 픽업 완료 처리 중 오류 발생
    if (result.error) {
      sendCloError(result.error);
      console.error(`[픽업완료] 주문번호(${order.oid}) 픽업 완료 요청 중 오류 발생 > `, result.error);
      WoAlert.fire('픽업 완료', '픽업 완료 처리 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요.', 'error');
      return false;
    }

    const { code, msg } = result.data.result;

    if (code !== 0) {
      sendCloError(msg);
      console.error(`[픽업완료] 주문번호(${order.oid}) 픽업 완료 요청 중 오류 발생 > `, msg);
      WoAlert.fire('픽업 완료', `픽업 완료 처리 중 오류가 발생했습니다.<br />[${msg}]<br /><br />잠시 후 다시 시도해주세요.`, 'error');
      return false;
    }

    WoAlert.fire('픽업 완료', '픽업 완료 처리되었습니다.', 'success').then(() => {
      reloadOrder();
    });
  };

  // CLO 에러 전송
  const sendCloError = (errorMsg) => {
    // clo
    const sendCloError = httpsCallable(getFirebaseFunctions(), 'call-cdm-clo-error');
    sendCloError({
      code: CLO_CODE.ORDER_REQUEST_ERROR,
      title: `[VENDOR APP] 주문 픽업 완료 요청 오류 [oid=${order.oid}] [error=${errorMsg}]`,
      msg: `${JSON.stringify(errorMsg)}`,
      which: `${location.pathname}${location.search}`
    })
      .then(console.log)
      .catch(console.error);
  };

  return (
    <>
      <LoadingLayout open={loading} text="픽업완료 처리 중 입니다." />
      <Box width="100%" height="70px" padding="10px 20px" position="fixed" bottom={0} right={0} bgcolor="#ffffff">
        <Button
          variant="contained"
          fullWidth
          sx={{
            height: '50px',
            backgroundColor: '#130328',
            borderRadius: '6px',
            color: '#ffffff',
            fontWeight: 700,
            fontSize: '16px'
          }}
          onClick={() => setPickOkModal(true)}
        >
          픽업 완료 처리
        </Button>
      </Box>

      {/* 픽업완료 컨펌 모달 */}
      <Dialog open={pickOkModal} fullWidth size="sm" sx={{ '& .MuiPaper-root': { padding: '5px' } }}>
        <DialogTitle>픽업 완료 처리</DialogTitle>
        <DialogContent>
          <Typography sx={{ fontSize: '16px', letterSpacing: '-0.16px' }}>픽업 완료 처리를 진행하시겠습니까?</Typography>
          <Stack mt="26px" direction="row" spacing="4px">
            <CheckIcon sx={{ width: 16, height: 16, color: '#9357E5' }} />
            <Box>
              <Typography component="span" sx={{ fontSize: '14px', letterSpacing: '-0.14px' }}>
                픽업 고객의
              </Typography>
              <Typography component="span" sx={{ fontSize: '14px', letterSpacing: '-0.14px', color: '#9357E5' }}>
                &nbsp;신분증을 확인
              </Typography>
              <Typography component="span" sx={{ fontSize: '14px', letterSpacing: '-0.14px' }}>
                하고,
              </Typography>
              <br />
              <Typography component="span" sx={{ fontSize: '14px', letterSpacing: '-0.14px', color: '#9357E5' }}>
                상품을 전달하신 후&nbsp;
              </Typography>
              <Typography component="span" sx={{ fontSize: '14px', letterSpacing: '-0.14px' }}>
                픽업 완료 처리를
              </Typography>
              <br />
              <Typography component="span" sx={{ fontSize: '14px', letterSpacing: '-0.14px' }}>
                진행해주세요.
              </Typography>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ px: '20px', pb: '20px' }}>
          <Stack direction="row" spacing={1} width="100%">
            <ButtonBase
              sx={{
                width: '100%',
                border: '1px solid #60546E4D',
                borderRadius: '26px',
                py: '16px',
                fontSize: '16px',
                letterSpacing: '-0.16px'
              }}
              onClick={() => setPickOkModal(false)}
            >
              취소
            </ButtonBase>
            <ButtonBase
              sx={{
                width: '100%',
                bgcolor: '#140229',
                borderRadius: '26px',
                py: '16px',
                fontSize: '16px',
                color: '#FFF',
                fontWeight: 'bold',
                letterSpacing: '-0.16px'
              }}
              onClick={() => pickOkApiCall()}
            >
              확인
            </ButtonBase>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ReqOkAction;

ReqOkAction.propTypes = {
  order: PropTypes.object,
  reloadOrder: PropTypes.func
};
