import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// project imports
import useAuth from 'hooks/useAuth';
import { getVendorInformation } from 'services/VendorService';
import { WoAlert } from 'utils/kmwine-alerts';
import { VendorAddress, VendorImage } from 'components/vendor';

// material-ui
import { Box, ButtonBase, Card, CardContent, Chip, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { alpha, styled } from '@mui/material/styles';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';

// assets
import DiscountChipIcon from 'assets/images/ico_shop_list_discount.png';
import { dispatch } from '../../../../store';
import { fetchCodes } from '../../../../store/slices/wo-constants';

// 입점샵 이미지 가로 크기
const VENDOR_IMG_WIDTH_SIZE = 90;
// 입점샵 이미지 세로 크기
const VENDOR_IMG_HEIGHT_SIZE = 90;

/**
 * 홈 > 입점샵 정보
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
const VendorInformation = () => {
  const { user } = useAuth();

  const classes = useStyles();
  const navigate = useNavigate();

  const { code } = useSelector((state) => state.woConstants);

  // 상수 관련 서비스
  const { woConstants } = useSelector((state) => state);

  React.useLayoutEffect(() => {
    // 서비스 코드 조회
    dispatch(fetchCodes(['service_tag']));
  }, [woConstants]);

  // 입점사 정보
  const [vendor, setVendor] = React.useState({ loaded: false, error: false, data: null });
  // 입점샵 이미지 정보
  const [vendorSrc, setVendorSrc] = React.useState(null);
  // 보여지는 tag길이
  const [shownElementsWidth, setShownElementsWidth] = React.useState(0);
  // 숨겨진 tag
  const [hiddenElements, setHiddenElements] = React.useState([]);

  // tag 요소 ref
  const parentRef = React.useRef(null);

  React.useEffect(() => {
    if (!user?.vendorId) return;

    getVendor();
  }, [user?.vendorId]);

  // 입점샵 정보 조회
  const getVendor = async () => {
    setVendor({ loaded: false, error: false, data: null });

    const { vendorId } = user;

    const body = {
      vendor_id: vendorId
    };

    const result = await getVendorInformation(body).catch((error) => ({ error }));

    // 입점샵 정보 조회 중 오류 발생
    if (result.error) {
      setVendor({ loaded: true, error: true, data: null });
      console.error('[VendorInformation][getVendor] 입점샵 정보 조회 중 오류 발생 > ', result.error);
      WoAlert.fire('', '입점샵 정보 조회 중 오류가 발생했습니다.', 'error');
      return undefined;
    }

    const { code, msg } = result.result;

    // 잘못된 Response값이 반환됨
    if (code !== 0) {
      setVendor({ loaded: true, error: true, data: null });
      console.error('[VendorInformation][getVendor] 잘못된 Response값이 반환됨 > ', msg);
      WoAlert.fire('', '입점샵 정보 조회 중 오류가 발생했습니다.', 'error');
      return undefined;
    }

    const _vendor = result.data;

    // 입점샵 대표 이미지
    const _vendorSrc = _vendor?.vendor_img[0]?.thumb;
    setVendorSrc(_vendorSrc);

    if (!_vendor) {
      setVendor({ loaded: true, error: true, data: null });
    } else {
      setVendor({ loaded: true, error: false, data: _vendor });
    }
  };

  // 매장 주소
  const vendorAddr = React.useMemo(() => {
    if (!vendor.loaded || vendor.error) return;

    let addr;

    const { data } = vendor;

    if (data?.biz.address1) {
      addr = data.biz.address1;
      if (data.biz.address2) addr += ` ${data.biz.address2}`;
    }

    return addr;
  }, [vendor.loaded]);

  // tag길이, 숨겨진 값 구하기
  const findHiddenElements = () => {
    if (parentRef.current) {
      const parentRect = parentRef.current.getBoundingClientRect();
      const { childNodes } = parentRef.current;

      const hiddenElements = [];
      let shownElementsWidth = 0;
      for (let i = 0; i < childNodes.length; i++) {
        const childNode = childNodes[i];

        const childRect = childNode.getBoundingClientRect();

        if (childRect.top > parentRect.top) {
          hiddenElements.push(childNode);
        } else {
          shownElementsWidth += childNode.offsetWidth;
        }
      }
      setHiddenElements(hiddenElements);
      setShownElementsWidth(shownElementsWidth);
    }
  };

  React.useEffect(() => {
    if (!vendor.loaded || !vendor.data) return;
    findHiddenElements();
  }, [parentRef, vendor.loaded]);

  // 프로모션 행사 중인 입점샵인 경우 할인 칩 표시
  const promotionChip = React.useMemo(() => {
    if (!vendor.loaded || vendor.error) return;

    const {
      data: { on_promo }
    } = vendor;

    if (!on_promo) return;

    return (
      <Grid item xs="auto">
        <ServiceChip
          avatar={<Box component="img" src={DiscountChipIcon} />}
          label="할인"
          color="default"
          size="small"
          sx={{
            bgcolor: '#220348',
            color: '#fff',
            '& .MuiChip-avatar': { width: '12px', height: '12px', margin: 0, marginRight: '2px' }
          }}
        />
      </Grid>
    );
  }, [vendor.loaded]);

  // render
  return (
    <Card
      square
      elevation={0}
      sx={{
        mx: '-20px',
        py: '16px',
        px: '20px',
        borderBottomLeftRadius: '20px',
        borderBottomRightRadius: '20px',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0
      }}
    >
      <Box
        component="section"
        position="relative"
        display="flex"
        width="100%"
        sx={{ flexDirection: 'row' }}
        justifyContent="center"
        alignItems="center"
      >
        {/* 샵 이미지 */}
        <VendorImage src={vendorSrc} width={VENDOR_IMG_WIDTH_SIZE} height={VENDOR_IMG_HEIGHT_SIZE} alt={`${vendor?._id}_image`} />

        {/* 샵 정보 영역 */}
        <Box className={classes.vendorInfoBox}>
          <CardContent className={classes.vendorCardContent}>
            {vendor.error && (
              <Box sx={{ width: '100%', height: '100%' }}>
                <ButtonBase sx={{ width: '100%', height: '100%' }} onClick={() => getVendor()}>
                  <ReplayOutlinedIcon sx={{ mr: 1 }} />
                  재시도
                </ButtonBase>
              </Box>
            )}
            <ButtonBase sx={{ display: 'flex', alignItems: 'center' }}>
              {/* 와인샵 이름 */}
              <Typography noWrap className={classes.vendorBizName} onClick={() => navigate('/my')}>
                {vendor?.data?.biz?.name}
              </Typography>
              {vendor.loaded && !vendor.error && <KeyboardArrowRightIcon sx={{ opacity: '50%' }} />}
            </ButtonBase>
            {/* 주소 */}
            <Box onwidth="100%" mt="6px">
              <VendorAddress>{vendorAddr}</VendorAddress>
            </Box>

            {/* 샵 tag chips[start] */}
            {!vendor.error && (
              <Box
                mt={1}
                display="flex"
                sx={{
                  maxWidth: '90%',
                  height: 24,
                  width: '90%',
                  position: 'absolute',
                  bottom: '2px'
                }}
              >
                <Grid
                  width={shownElementsWidth !== 0 ? `${shownElementsWidth + 1}px` : '85%'}
                  overflow="hidden"
                  container
                  spacing="4px"
                  ref={parentRef}
                >
                  {vendor?.test ? (
                    <Grid item xs="auto">
                      <ServiceChip label="TEST" color="error" size="small" />
                    </Grid>
                  ) : null}

                  {/* 프로모션 진행 중인 와인샵 일 경우 표시 */}
                  {promotionChip}

                  {/* 입점샵 서비스 칩 */}
                  {vendor.loaded &&
                    code.service_tag &&
                    vendor?.data?.shop?.service_tag?.length > 0 &&
                    vendor.data.shop.service_tag.map((tag, i) => (
                      <Grid item xs="auto" key={`v-svc-tag-${i}-chip`}>
                        <ServiceTag tagCode={tag} />
                      </Grid>
                    ))}
                </Grid>

                {vendor.loaded && shownElementsWidth !== 0 && hiddenElements.length > 0 && (
                  <Box marginLeft="4px">
                    <ServiceChip
                      label={`+${hiddenElements.length}`}
                      color="default"
                      size="small"
                      sx={(theme) => ({ bgcolor: '#ffffff', border: `1px solid ${alpha(theme.palette.brand.main, 0.04)}` })}
                    />
                  </Box>
                )}
              </Box>
            )}
          </CardContent>
        </Box>
      </Box>
    </Card>
  );
};

export default VendorInformation;

const ServiceChip = styled(Chip)`
  height: 18px;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: -0.41px;
  line-height: 1.3;
  border-radius: 2px;
  padding: 4px 6px;
  & .MuiChip-label {
    padding: 0;
  }
  & .MuiSvgIcon-root {
    margin-right: 2px;
  }
`;

const useStyles = makeStyles(() => ({
  vendorInfoBox: {
    display: 'flex',
    flexDirection: 'column',
    width: `calc( 100% - ${VENDOR_IMG_WIDTH_SIZE}px )`,
    height: VENDOR_IMG_HEIGHT_SIZE
  },
  vendorCardContent: {
    paddingTop: 0,
    paddingBottom: 0,
    flex: '1',
    width: '100%',
    position: 'relative'
  },
  vendorBizName: {
    maxWidth: '85%',
    fontSize: '16px',
    fontWeight: 750,
    letterSpacing: '-0.11px'
  }
}));

/* eslint-disable react/prop-types */
const ServiceTag = ({ tagCode, ref, ...other }) => {
  const { code } = useSelector((state) => state.woConstants);

  if (!code.service_tag) {
    console.warn('서비스태그(service_tag) 상수가 아직 로드되지 않았습니다.');
    return null;
  }

  const tag = (
    <ServiceChip
      label={code.service_tag[tagCode].ko}
      color="default"
      size="small"
      sx={(theme) => ({ bgcolor: alpha(theme.palette.brand.main, 0.04) })}
      {...other}
    />
  );

  return tag;
};
