import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Card, Container, Skeleton } from '@mui/material';
import RefreshIcon from 'assets/images/ic_refresh.png';
import { useAuth } from 'hooks';
import { WoAlert } from 'utils/kmwine-alerts';

// import firebase
import { collection, getDocs, query, where } from 'firebase/firestore';
import { firebaseDB } from 'index';
import moment from 'moment';
import VendorDayoff from './VendorDayoff';
import { WoDivider } from '../../components/dialog';

function Management() {
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  let vendorId;

  if (user) {
    try {
      const vendorReload = JSON.parse(user.reloadUserInfo.customAttributes);
      vendorId = vendorReload.vendor;
    } catch (e) {
      navigate(0);
    }
  }

  // 오늘 주문 금액 합계
  const [totalAmount, setTotalAmount] = React.useState(null);
  // refresh icon shake
  const [shake, setShake] = React.useState(false);
  const refresh = () => {
    setTotalAmount(null);
    setShake(true);
    getTodayTotalAmount();
    setShake(false);
  };

  // 페이지 진입 시
  React.useEffect(() => {
    getTodayTotalAmount();
  }, []);

  // 오늘 주문 금액 합계 조회
  const getTodayTotalAmount = () => {
    const date = new Date();
    const orderRef = collection(firebaseDB, 'order');
    const orderQuery = query(
      orderRef,
      where('vendor.id', '==', vendorId),
      where('paid', '==', true),
      where('created_at', '>=', moment(date).startOf('day').toDate()),
      where('created_at', '<=', moment(date).endOf('day').toDate())
    );

    const orderSnapshot = getDocs(orderQuery);

    let totalAmount = 0;
    orderSnapshot
      .then((res) => {
        res.forEach((r) => {
          totalAmount += r.data().pay.product;
        });
        setTotalAmount(totalAmount);
      })
      .catch((e) => {
        console.error('[운영관리] 오늘 주문 금액 합계 조회 중 오류 발생 > ', e);
      });
  };

  return (
    <main>
      <Container>
        <Box pt={2} pb={2}>
          <Card style={{ backgroundColor: '#F4F0F8' }}>
            <Box display={'flex'} mt={2} ml={2} mr={2}>
              <Typography variant={'h4'} fontSize={16} fontWeight={600} flexGrow={1}>
                오늘 주문 금액 합계
              </Typography>
              <Box
                component="img"
                className={shake ? 'refresh_shake' : 'refresh'}
                src={RefreshIcon}
                width={16}
                height={22}
                onClick={() => refresh()}
              />
            </Box>
            <Box justifyContent={'right'} display={'flex'} mt={3} pb={3}>
              {totalAmount === null ? (
                <Skeleton variant={'text'} width={'100px'} />
              ) : (
                <Typography varient={'p'} fontSize={22} fontWeight={700}>
                  {totalAmount.toLocaleString('ko-KR')}
                </Typography>
              )}
              <Typography varient={'p'} fontSize={22} fontWeight={700} mr={2}>
                원
              </Typography>
            </Box>
            <Box
              justifyContent={'center'}
              display={'flex'}
              pt={2}
              pb={2}
              border={'2px solid #ECEBED'}
              onClick={() => {
                navigate('orderList');
              }}
            >
              <Typography varient={'p'} fontSize={14} fontWeight={600}>
                지난 주문내역 보기
              </Typography>
            </Box>
          </Card>

          <Box mt="40px">
            <WoDivider px={0} />
            <VendorDayoff />
            <WoDivider px={0} />
          </Box>

          <Box fontSize={12} color={'#B0B0B0'} pt={2} pb={2} mt={1.5}>
            앱 설정
          </Box>
          <Box fontSize={16} pt={2.5} pb={2.5} onClick={() => navigate('settingPush')}>
            푸시알람 설정
          </Box>
          <Box
            fontSize={16}
            pt={2.5}
            pb={2.5}
            onClick={() => {
              WoAlert.fire({
                title: (
                  <Typography component="div" fontSize={18} fontWeight="bold" textAlign="center">
                    로그아웃
                  </Typography>
                ),
                html: `<div style='line-height: 2rem; text-align: left'><span style="font-size: 16px;">로그아웃을 진행 하시겠습니까?</span></div>`,
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonText: '확인',
                cancelButtonText: '취소',
                customClass: {
                  confirmButton: 'max-50',
                  cancelButton: 'max-50'
                }
              }).then((result) => {
                if (result.isConfirmed) {
                  logout();
                  navigate('/intro');
                }
              });
            }}
          >
            로그아웃
          </Box>
          <Box fontSize={16} color={'#555555'} pt={2.5} pb={2.5}>
            앱 버전 v.1.0.0
          </Box>
        </Box>
      </Container>
    </main>
  );
}

export default Management;
