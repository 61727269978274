// action - state management
import { LOGIN, LOGOUT, REFRESH, REGISTER } from './actions';
import { logEvent, setUserId } from 'firebase/analytics';
import { analytics } from '../index';
import { getAuth } from 'firebase/auth';
import { DEV } from '../config';

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER: {
      const { user } = action.payload;
      return {
        ...state,
        user
      };
    }
    // 로그인 처리
    case LOGIN: {
      const { user, isLoggedIn } = action.payload;

      // Google analytics: https://developers.google.com/analytics/devguides/collection/ga4/reference/events#login
      if (process.env.NODE_ENV === 'production') {
        const { currentUser } = getAuth();
        logEvent(analytics, 'login', {
          method: currentUser.providerData[0].providerId
        });
        setUserId(analytics, currentUser.uid);
      }

      return {
        ...state,
        isInitialized: true,
        isLoggedIn,
        user
      };
    }
    // 사용자 정보(user) 갱신
    case REFRESH: {
      const { user } = action.payload;
      if (process.env.NODE_ENV === 'development') {
        console.groupCollapsed(`%cwineone`, DEV.CONSOLE.LABEL_STYLE, `로그인 사용자정보가 갱신되었습니다.`);
        console.log('user: ', user);
        console.groupEnd();
      }
      return {
        ...state,
        user
      };
    }
    // 로그아웃
    case LOGOUT: {
      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        user: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default accountReducer;
