import React from 'react';
import moment from 'moment';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * 주문, 교환, 반품 경과시간
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function LapsOfTime(val) {
  const now = moment();
  const createdAt = moment.utc(val).format('YYYY-MM-DD HH:mm:ss');
  const lapse = Math.ceil(moment.duration(now.diff(createdAt)).asMinutes());

  let warning;

  if (lapse >= 45) {
    warning = true;
  } else {
    warning = false;
  }

  return (
    <Typography component="span" fontSize="13px" color={warning ? '#FC735C' : '#333333'} fontWeight={800} ml={2}>
      {lapse}분
    </Typography>
  );
}

export default LapsOfTime;

LapsOfTime.propTypes = {
  val: PropTypes.instanceOf(Date)
};
