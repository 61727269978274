import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { collection, doc, getDoc, getDocs, orderBy, query } from 'firebase/firestore';

// project imports
import { WoAlert } from 'utils/kmwine-alerts';
import { WineBottleLoadingLottie } from 'components/spinner';
import ReqOkAction from './components/action/ReqOkAction';
import { ToolbarTitle } from 'components/toolbar';
import TabHeader from './tab/TabHeader';
import TabContents from './tab/TabContents';
import { firebaseDB } from 'index';

// material-ui
import { AppBar, Backdrop, Box, Toolbar, Typography } from '@mui/material';

// assets
import BackIcon from 'assets/images/arrow-back.png';

/**
 * 주문 상세 화면
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function DetailTab() {
  const { user } = useAuth();

  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = React.useState(true);

  // 주문 & 상품 데이터 조회 성공 여부
  const [searchSuccess, setSearchSuccess] = React.useState(false);
  // 주문정보
  const [order, setOrder] = React.useState(null);
  // 주문 이력 정보
  const [history, setHistory] = React.useState([]);
  // 상품정보
  const [products, setProducts] = React.useState([]);

  // 현재 선택한 탭
  const [selectTab, setSelectTab] = React.useState(1);

  React.useEffect(() => {
    getOrderData();
    setSelectTab(1);
  }, [params]);

  // 주문 상세 조회
  const getOrderData = async () => {
    const oid = params.id;
    console.log(`[주문상세] 주문번호(${oid})의 주문상세, 주문상품 정보 조회 시작`);
    setSearchSuccess(false);
    setLoading(true);

    // 주문정보 조회
    const orderSnapshot = await getDoc(doc(firebaseDB, 'order', oid)).catch((error) => ({ error }));

    // 주문정보 조회 중 오류 발생
    if (orderSnapshot.error) {
      console.error(`[주문 상세] 주문정보(${oid}) 조회 중 오류 발생 > `, orderSnapshot.error);
      WoAlert.fire('주문 상세', '주문정보 조회 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요', 'error').then(() => {
        if (window.history.length > 0) {
          navigate(-1);
        } else {
          navigate('/home', { replace: true });
        }
      });
      return false;
    }

    // 주문정보가 올바르지 않음
    if (!orderSnapshot.exists()) {
      console.error(`[주문 상세] 주문정보(${oid})가 올바르지 않음`);
      WoAlert.fire('주문 상세', '주문정보가 올바르지 않습니다.<br />잠시 후 다시 시도해주세요', 'error').then(() => {
        if (window.history.length > 0) {
          navigate(-1);
        } else {
          navigate('/home', { replace: true });
        }
      });
      return false;
    }

    let orderData = orderSnapshot.data();

    const { promotion_id } = orderSnapshot.data();

    // 플랫폼 프로모션 주문임
    if (promotion_id) {
      const promotionSnapshot = await getDoc(doc(firebaseDB, 'promotion', promotion_id)).catch((error) => ({ error }));

      // 플랫폼 프로모션 정보 조회 중 오류 발생
      if (promotionSnapshot.error) {
        console.error('[DetailTab][getOrderData] 플랫폼 프로모션 정보 조회 중 오류 발생 > ', promotionSnapshot.error);
        WoAlert.fire('', '주문정보 조회 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요.', 'error');
        return undefined;
      }

      // const promotion

      orderData = { ...orderData, promotion: promotionSnapshot.data() };
    }

    // 주문정보가 vendor와 맞지 않음
    if (user.vendorId !== orderSnapshot.data().vendor.id) {
      console.error(`[주문 상세] 다른 샵의 주문정보입니다`);
      WoAlert.fire('주문 상세', '다른 샵의 주문정보입니다', 'error').then(() => {
        if (window.history.length > 0) {
          navigate(-1);
        } else {
          navigate('/home', { replace: true });
        }
      });
      return false;
    }

    setOrder(orderData);

    // 픽업시간 변경 이력 조회
    const orderHistorySnapshot = await getDocs(query(collection(orderSnapshot.ref, 'order_history'), orderBy('updated_at', 'asc'))).catch(
      (error) => ({ error })
    );

    // 픽업시간 변경 이력 조회 중 오류 발생
    if (orderHistorySnapshot.error) {
      console.error('[주문 상세] 픽업시간 변경 이력 조회 중 오류 발생 > ', orderHistorySnapshot.error);
    }

    if (!orderHistorySnapshot.error) {
      const { docs: historyDocs, empty, size } = orderHistorySnapshot;
      const historyTempArray = [];
      if (!empty && size > 0) {
        for (let i = 0; i < size; i++) {
          const historyData = historyDocs[i].data();
          // 픽업시간을 변경한 이력이 있을 시
          if (historyData.prev_pickup_date && historyData.prev_pickup_time) {
            historyTempArray.push(historyData);
          }
        }
      }
      setHistory(historyTempArray);
    }

    // 주문상품 조회
    const orderProductSnapshot = await getDocs(collection(firebaseDB, `order/${oid}/order_products`))
      .catch((error) => ({ error }))
      .finally(() => setLoading(false));

    // 주문상품 조회 중 오류 발생
    if (orderProductSnapshot.error) {
      console.error(`[주문 상세] 주문번호(${oid})의 주문상품 목록 조회 중 오류 발생 > `, orderProductSnapshot.error);
      WoAlert.fire('주문 상세', '주문 상품정보 조회 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요', 'error').then(() => {
        if (window.history.length > 0) {
          navigate(-1);
        } else {
          navigate('/home', { replace: true });
        }
      });
      return false;
    }

    const { docs, empty, size } = orderProductSnapshot;

    // 주문상품이 비어있음(오류)
    if (empty || size === 0) {
      console.error(`[주문 상세] 주문번호(${oid})의 주문상품 목록 조회 중 오류 발생 > `, orderProductSnapshot.error);
      WoAlert.fire('주문 상세', '주문 상품정보가 올바르지 않습니다.<br />잠시 후 다시 시도해주세요', 'error').then(() => {
        if (window.history.length > 0) {
          navigate(-1);
        } else {
          navigate('/home', { replace: true });
        }
      });
      return false;
    }

    // 주문상품 임시배열
    const temp = [];

    for (let i = 0; i < size; i++) {
      temp.push(docs[i].data());
    }

    setProducts(temp);
    await setSearchSuccess(true);
    console.log(`[주문상세] 주문번호(${oid})의 주문상세, 주문상품 정보 조회 종료`);
  };

  return (
    <Box>
      <Backdrop open={loading} sx={{ zIndex: 1211 }}>
        <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <WineBottleLoadingLottie />
          <Typography color="#ffffff">주문 정보를 불러오고 있습니다</Typography>
        </Box>
      </Backdrop>
      {/* HEADER */}
      <AppBar position="sticky">
        <Toolbar sx={{ padding: '10px 20px' }}>
          <Box component="img" src={BackIcon} width={34} height={34} onClick={() => navigate(-1)} />
          <ToolbarTitle>주문확인</ToolbarTitle>
        </Toolbar>
      </AppBar>
      <Box position="relative">
        {/* 탭 헤더 */}
        <TabHeader loading={loading} selectTab={selectTab} changeTabEvent={(tab) => setSelectTab(tab)} orderState={order?.state} />

        {/* 탭 컨텐츠 */}
        {!loading && searchSuccess && <TabContents selectTab={selectTab} order={order} products={products} history={history} />}

        {/* 주문의 상태값이 픽업 대기(REQ_OK일) 경우 하단 픽업 완료 버튼 생성 */}
        {!loading && searchSuccess && order.state === 'REQ_OK' && <ReqOkAction order={order} reloadOrder={() => getOrderData()} />}
      </Box>
    </Box>
  );
}

export default DetailTab;
