import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { collection, getDocs } from 'firebase/firestore';

// material-ui
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Typography } from '@mui/material';

// project imports
import { firebaseDB } from 'index';
import { WoAlert } from 'utils/kmwine-alerts';
import { bottleCapacityToString } from 'config';
import LapsOfTime from '../../../components/LapsOfTime';
import MDateFormatAhm from '../../../components/MDateFormatAhm';
import MDateFormatYMDHm from '../../../components/MDateFormatYMDHm';
import { SelectArrow, OrderOverIcons } from 'components/icons';

/**
 * 승인대기 목록
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function RequestWaitList({ rows }) {
  const navigate = useNavigate();

  // 클릭한 주문건의 주문상품 정보 조회 로딩
  const [loading, setLoading] = React.useState(false);

  // 주문상품 목록
  const [productRows, setProductRows] = React.useState([]);
  // 주문상품 개수
  const [productCounts, setProductCounts] = React.useState(0);

  // 아코디언 열림여부
  const [expanded, setExpanded] = React.useState(false);

  // 아코디언 열림/닫힘
  const handleChange = (panel, oid) => async (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      setLoading(true);

      const productsSnapshot = await getDocs(collection(firebaseDB, `order/${oid}/order_products`)).catch((error) => ({ error }));

      // 주문 상품 목록 조회 중 오류 발생
      if (productsSnapshot.error) {
        WoAlert.fire('주문 상품', '주문상품 조회 중 오류가 발생했습니다.', 'error');
        return false;
      }

      const { docs, empty } = productsSnapshot;

      // 주문 상품이 조회되지 않을 경우
      if (empty) {
        WoAlert.fire('주문 상품', '주문상품 데이터가 올바르지 않습니다.', 'error');
        return false;
      }

      const tempProducts = [];
      let totalCounts = 0;
      docs.forEach((d) => {
        tempProducts.push(d.data());
        totalCounts += d.data().quantity;
      });

      setProductRows(tempProducts);
      setProductCounts(totalCounts);
      await setLoading(false);
    }
  };

  return (
    <Box paddingBottom="20px">
      {rows.map((r, index) => (
        <Accordion
          key={index}
          expanded={expanded === 'req_' + index}
          onChange={handleChange('req_' + index, r.oid)}
          sx={{
            marginTop: '11px',
            backgroundColor: '#ffffff',
            boxShadow: '0px 0px 26px #1303281A',
            border: '1px solid #ffffff',
            borderRadius: '10px !important',
            '& .Mui-expanded': {
              margin: 0
            },
            '&:before': {
              backgroundColor: 'transparent'
            }
          }}
        >
          <AccordionSummary expandIcon={<SelectArrow />}>
            <Box width="100%">
              {/* 주문경과 & 주문시간 */}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                onClick={() => navigate(`/order/${r.oid}`, { state: r.state })}
              >
                <Box display="flex" alignItems="center">
                  <OrderOverIcons />
                  <Typography component="span" fontSize="13px" color="#333333CC" ml={1}>
                    주문경과
                  </Typography>
                  {LapsOfTime(r.created_at)}
                </Box>
                <Box>
                  <Typography components="span" color="#33333366" fontSize="11px">
                    {MDateFormatAhm(r.created_at)}
                  </Typography>
                </Box>
              </Box>

              {/* 주문 상품 */}
              <Box mt={2} mb={1.5} mr={4} onClick={() => navigate(`/order/${r.oid}`, { state: r.state })}>
                <Typography className="line-1-ellipsis" component="span" fontSize="14px" color="#333333">
                  {r.goods_name ?? '-'}
                </Typography>
              </Box>

              {/* 픽업일시 */}
              <Box mr={4} onClick={() => navigate(`/order/${r.oid}`, { state: r.state })}>
                <Typography component="span" color="#33333399" fontSize="12px">
                  픽업일시 : {MDateFormatYMDHm(r.pickup_date_time)}
                </Typography>
              </Box>

              {/* 결제금액 */}
              <Box mr={4} onClick={() => navigate(`/order/${r.oid}`, { state: r.state })}>
                <Typography component="span" color="#33333399" fontSize="12px">
                  결제금액 : {r.pay__amount?.toLocaleString() ?? '-'}원
                </Typography>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box borderTop="1px solid #CBCBCB">
              <Box mt={1}>
                <Typography variant="body1" fontWeight={600}>
                  상품 요약 (총 {loading ? <CircularProgress size={12} /> : productCounts.toLocaleString()}개)
                </Typography>

                {loading ? (
                  <Box width="100%" textAlign="center">
                    <CircularProgress size={18} />
                  </Box>
                ) : (
                  productRows.map((p, index) => (
                    <Typography key={index} mb={0.5} variant="body2" color="#33333399">
                      {p.name.ko} {`${bottleCapacityToString(p.capacity)}`}/{`${p.vintage}년`} {p.quantity}개
                    </Typography>
                  ))
                )}
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}

export default RequestWaitList;

RequestWaitList.propTypes = {
  rows: PropTypes.array.isRequired
};
