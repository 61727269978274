import axios from 'axios';
import { httpsCallable } from 'firebase/functions';

// project imports
import { CLO_CODE } from 'config';
import { getFirebaseFunctions } from 'utils/firebase-tools';
import { FIREBASE_FUNCTION_URL } from './index';

/**
 * 정산목록 검색
 */
export async function calculateGetList(calculateParams) {
  return axios
    .post(FIREBASE_FUNCTION_URL.POST__CALCULATE_GET_LIST, { ...calculateParams })
    .then((res) => res.data)
    .catch((error) => {
      if (error.message !== 'Network Error') {
        httpsCallable(
          getFirebaseFunctions(),
          'call-cdm-clo-error'
        )({
          code: CLO_CODE.UNEXPECTED_ERROR,
          title: `[Vendor APP] 입점샵 정산 예정 내역 합계 조회 중 오류 발생 [${error.message}:${error.code ?? 'Unexpected'}]`,
          msg: `${JSON.stringify(error)}`,
          which: `calculateGetList`,
          param: { error, location: window.location.href }
        })
          .then(console.log)
          .catch(console.error);
      }
      throw error;
    });
}
