import React, { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

// third-party: firebase
import { browserLocalPersistence, getAuth, setPersistence, signInWithEmailAndPassword } from 'firebase/auth';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'components/Loader';
import { useLocation } from 'react-router-dom';
import { removeFcmToken } from '../services/UserService';
import useMobileNative from '../hooks/useMobileNative';

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const AuthContext = createContext(null);

export const WineOneAuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);
  const location = useLocation();

  const auth = getAuth();
  const { onAuthChecked, getFcmToken } = useMobileNative();

  // 최초
  useEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged((user) => {
      // detaching the listener
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          const { claims } = idTokenResult;
          user.vendorId = claims.vendor;
          console.log('[wineone] 이미 로그인 되어있는 사용자입니다.', user);
          console.log('[wineone] login access token : ', user.accessToken);
          dispatch({ type: LOGIN, payload: { isLoggedIn: true, user } });
        });
      } else {
        // No user is signed in...code to handle unauthenticated users.
        logout();
      }
    });
    return () => unsubscribe();
  }, []);

  /**
   * 입점샵 로그인
   *
   * @param email 사용자 아이디 (이메일)
   * @param password 비밀번호
   * @param callback
   * @returns {Promise<void>}
   */
  const login = async (email, password, callback) => {
    console.debug(`[AuthContext][@login] 로그인 시작. email='${email}'`);
    const auth = getAuth();
    const userCredential = await signInWithEmailAndPassword(auth, email, password).catch((error) => ({ error }));

    // 로그인 요청 중 오류 발생
    if (userCredential.error) {
      const { error } = userCredential;
      console.warn(`[AuthContext] 입점샵 로그인 실패 'signInWithEmailAndPassword' error.`, error);
      callback(error);
      return undefined;
    }

    // Firebase authentication credential
    const { user: firebaseUser } = userCredential;

    // claims 검사
    const idTokenResult = await firebaseUser.getIdTokenResult().catch((error) => ({ error }));

    // 토큰정보 조회 실패
    if (idTokenResult.error) {
      const { error } = idTokenResult;
      console.error('[1KMWINE - VENDOR] 로그인 사용자 토큰조회 실패. ', error);
      callback(error);
      return undefined;
    }

    // Firebase user claims.
    const { claims } = idTokenResult;
    console.debug(`[AuthContext][@login] 로그인 시도한 사용자의 'claims' 체크.`, claims);

    // role이 입점샵(vendor)가 아닐 경우
    if (claims?.role !== 'vendor') {
      await logout(); // 로그아웃 처리
      // 입점샵 계정이 아닐 경우, 계정 추측을 막기 위해 로그인 사용자가 아니라는 token 처리
      console.warn(`[1KMWINE - VENDOR] 'vendor'권한을 가지지 않은 사용자의 로그인 시도.`);
      callback({ code: 'not-existing-customer' });
      return undefined;
    }

    await setPersistence(auth, browserLocalPersistence);
  }; // end of login

  const logout = async (callback) => {
    // FCM token 제거
    try {
      removeFcmToken();
    } catch (e) {
      console.error(e);
    }

    const logoutResult = await auth.signOut();
    console.log('@logout.result: ', logoutResult);
    dispatch({ type: LOGOUT });

    if (typeof callback === 'function') {
      callback();
    } else {
      const [_, firstPath] = location.pathname.split('/');
      if (firstPath !== 'auth') {
        // const { pathname, search, hash } = location;
        // navigate(`/auth/sign-in?from=${encodeURIComponent(pathname + search + hash)}`, { replace: true });
      }
    }
    return logoutResult;
  };

  /**
   * 로그인시 네이티브 호출
   */
  React.useEffect(() => {
    if (state.isInitialized && state.isLoggedIn) {
      onAuthChecked();
      getFcmToken();
    }
  }, [state.isInitialized, state.isLoggedIn]);

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return <AuthContext.Provider value={{ ...state, login, logout }}>{children}</AuthContext.Provider>;
};

WineOneAuthProvider.propTypes = {
  children: PropTypes.node
};

export default AuthContext;
