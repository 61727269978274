import React from 'react';

// project imports
import Form from './components/Form';
import Container from 'components/Container';

// material-ui
import { Box, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { WoAlert } from 'utils/kmwine-alerts';

/**
 * 1kmwine 아이디로 로그인 화면
 * @constructor
 */
function SignInWithIdPage() {
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  });

  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: '로그인 실패'
  });

  // 로그인 실패시
  const onLoginFailure = (error) => {
    let message = '로그인에 실패했습니다.';
    const { errorCode } = error;
    if (errorCode === 'not-existing-customer' || errorCode === 'invalid-password') {
      message = '아이디 또는 비밀번호가 올바르지 않습니다.';
    }

    WoAlert.fire(message, '', 'error');

    setSnackbar({
      ...snackbar,
      message,
      open: true
    });
  };

  return (
    <Box>
      <main>
        <Box position={'relative'} minHeight={'calc(var(--vh, 1vh) * 100)'}>
          <Container>
            <Grid container spacing={6}>
              {isMd ? (
                <Grid item container justifyContent={'center'} xs={12} md={6}>
                  <Box height={1} width={1} maxWidth={500}>
                    <Box
                      component={'img'}
                      src={'https://assets.maccarianagency.com/svg/illustrations/drawkit-illustration2.svg'}
                      width={1}
                      height={1}
                      sx={{
                        filter: theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none'
                      }}
                    />
                  </Box>
                </Grid>
              ) : null}
              <Grid item container alignItems={'center'} justifyContent={'center'} xs={12} md={6}>
                <Form onLoginFailure={onLoginFailure} />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </main>
    </Box>
  );
}

export default SignInWithIdPage;
