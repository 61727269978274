import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import validator from 'validator/es';
import moment from 'moment';

// project imports
import useAuth from 'hooks/useAuth';
import { bottleCapacityToString, PDATA_CATEGORY } from 'config';
import { WoAlert } from 'utils/kmwine-alerts';
import palette_ from 'assets/scss/_palette.scss';
import * as ProductService from 'services/ProductService';
import { ToolbarTitle } from 'components/toolbar';
import SpaceBetweenBox from 'components/SpaceBetweenBox';
import ProductCreate from '../ProductCreate';
import ProductSearchBar from './components';
import ProductDetail from '../ProductDetail/ProductDetail';
import ProductUpdate from '../ProductUpdate';
import { headCells } from './index';
import './ProductStyle.scss';

// material-ui
import {
  AppBar,
  Box,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Skeleton,
  Stack,
  SvgIcon,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tabs,
  Toolbar,
  Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

const PDATA_CATEGORIES = Object.keys(PDATA_CATEGORY).map((key) => PDATA_CATEGORY[key]);

/**
 * 상품목록
 *
 * @constructor
 * @authors 최효근<hkchoi@wineone.io>, 이재일<leeji@wineone.io>
 */
const ProductList = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  // 검색어
  const [searchWord, setSearchWord] = React.useState(null);
  // 상품추가 모달
  const [openAddProduct, setOpenAddProduct] = React.useState(false);
  // 카테고리
  const [category, setCategory] = React.useState('all');

  /* 조회조건 [start] */
  // 정렬방식 'asc', 'desc'
  const [order, setOrder] = React.useState('desc');
  // 정렬필드
  const [orderBy, setOrderBy] = React.useState('created_at');
  // 조회 페이지
  const [page, setPage] = React.useState(1);

  // 전체 페이지수
  const [totalPages, setTotalPages] = React.useState(0);
  // 한번에 보여줄 데이터 열 개수
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  // 검색어
  const [searchQuery, setSearchQuery] = React.useState('');
  // 검색어 입력 디바운스
  const [searchDebounced] = useDebounce(searchQuery, 400);
  /* 조회조건 [end] */

  // 프로모션 상품만 보기 여부
  const [showPromo, setShowPromo] = React.useState(false);
  // 프로모션 상품 보기 여부 저장
  const [showPromoSaved, setShowPromoSaved] = React.useState(false);

  // 테이블에 보여줄 데이터 열(row) 배열
  const [rows, setRows] = React.useState({ loaded: false, products: [] });

  // 선택한 pid
  const [selectedPid, setSelectedPid] = React.useState('');
  // 선택한 product
  const [selectedProduct, setSelectedProduct] = React.useState('');
  // 선택한 product index
  const [selectedProductIndex, setSelectedProductIndex] = React.useState(0);

  // detail 열기
  const [openDetail, setOpenDetail] = React.useState(false);

  // 상품 정보 수정 모달 열기
  const [openProductUpdateModal, setOpenProductUpdateModal] = React.useState(false);

  // 조회된 아이템 전체 개수
  const [totalCounts, setTotalCounts] = React.useState(0);

  // 상품목록 조회
  React.useEffect(() => {
    getProductList(1);
  }, [showPromo, rowsPerPage, searchDebounced, order, orderBy, openAddProduct, category]);

  // 상품목록 조회
  const getProductList = async (_page) => {
    setRows({ loaded: false, products: [] });

    // 프로모션 상품 보기 여부가 변경되면 페이지를 1로 초기화
    if (showPromo !== showPromoSaved) {
      _page = 1;
    }

    // 프로모션 상품만 조회
    if (showPromo) {
      console.log(`[상품목록] 프로모션 상품 목록 조회 시작. [page=${_page}]`);

      const { vendorId } = user;

      const body = {
        page: {
          current: _page,
          size: rowsPerPage
        },
        states: ['WAIT', 'ING'],
        vendor_ids: [vendorId],
        pdata_category: category === 'all' ? null : [category],
        order_by: []
      };

      if (orderBy === 'created_at') {
        body.order_by.push(['sort', 'asc']);
      } else if (orderBy === 'quantity') {
        body.order_by.push(['qty', order]);
      } else if (orderBy === 'price__original') {
        body.order_by.push(['price_original', order], ['qty_0_l', 'desc']);
      }

      // 프로모션 상품명으로 검색
      if (typeof searchQuery === 'string' && !validator.isEmpty(searchQuery)) {
        body.search_field = 'product_name';
        body.search_text = searchQuery.trim();
      }

      const response = await ProductService.getPromotionProductList(body).catch((error) => ({ error }));

      // 프로모션 상품 목록 조회 중 오류 발생
      if (response.error) {
        const { error } = response;
        console.error('[상품관리] 프로모션 상품 목록 조회 오류 발생 > ', error);

        WoAlert.fire({
          title: '상품 관리',
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: '뒤로가기',
          cancelButtonTextColor: '#fff',
          html: `<div style='line-height: 1.5rem; text-align: center'>프로모션 상품 목록 조회 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요.</div>`
        }).then((result) => {
          if (result.isConfirmed) {
            getProductList();
          } else {
            navigate(-1);
          }
        });
        return undefined;
      }

      const { page, products, success } = response.data;

      if (!success) {
        WoAlert.fire({
          title: '상품 관리',
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: '뒤로가기',
          cancelButtonTextColor: '#fff',
          html: `<div style='line-height: 1.5rem; text-align: center'>프로모션 상품 목록 조회 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요.</div>`
        }).then((result) => {
          if (result.isConfirmed) {
            getProductList();
          } else {
            navigate(-1);
          }
        });
        return undefined;
      }

      const totalPages = Math.ceil(page.total_results / page.size);

      setPage(page.current);
      setTotalCounts(page.total_results);
      setTotalPages(totalPages);
      setRows({ loaded: true, products });
      setShowPromoSaved(showPromo);
    }
    // 전체 상품 목록 조회
    else {
      console.log(`[상품목록] 전체 상품 목록 조회 시작. [page=${_page}]`);

      const body = {
        page: {
          current: _page,
          size: rowsPerPage
        },
        category: category === 'all' ? null : [category],
        order
      };

      /**
       * 벤더앱 상품목록 정렬은 기존과 다르게 처리
       * 1. 날짜순(created_at)/재고(quantity) 정렬 -> 기존 정렬과 동일함
       * 2. 상품명(price__original)/가격(name__ko) 정렬 -> 품절 상품 하단 고정
       */
      if (orderBy === 'created_at') {
        body.order_by = 'created_at';
      } else if (orderBy === 'quantity') {
        body.order_by = 'quantity';
      } else if (orderBy === 'price__original') {
        body.order_by = 'quantity = 0, ';
        body.order = `${orderBy} ${order}`;
      }

      if (!body.order_by || !body.order) {
        console.error(`[전체상품관리] 상품목록 정렬 조회 중 오류 발생 > [orderBy]: ${orderBy}, [order]: ${order}`);
        WoAlert.fire('전체 상품 관리', '상품목록 조회 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요.', 'error');
        return false;
      }

      // 상품명으로 검색
      if (typeof searchQuery === 'string' && !validator.isEmpty(searchQuery)) {
        body.name = searchQuery.trim();
      }

      // 전체 상품 목록 조회 요청
      const response = await ProductService.getProductList(body).catch((error) => ({ error }));

      // 전체 상품 목록 조회 중 오류 발생
      if (response.error) {
        const { error } = response;
        console.error('[상품관리] 전체 상품 목록 조회 오류 발생 > ', error);

        WoAlert.fire({
          title: '상품 관리',
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: '뒤로가기',
          cancelButtonTextColor: '#fff',
          html: `<div style='line-height: 1.5rem; text-align: center'>상품 목록 조회 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요.</div>`
        }).then((result) => {
          if (result.isConfirmed) {
            getProductList();
          } else {
            navigate(-1);
          }
        });
        return undefined;
      }

      console.debug('[상품관리] 조회된 전체 상품 목록: ', response.data.data.result);

      const { data, page } = response.data;

      setPage(page.current);
      setTotalPages(page.total_pages);
      setTotalCounts(page.total_results);
      setRows({ loaded: true, products: data.result });
      setShowPromoSaved(showPromo);
    }
  };

  // 검색어 입력값 처리
  React.useEffect(() => {
    handleSearch();
  }, [searchWord]);

  const handleSearch = () => {
    const newString = searchWord;
    setPage(1); // 첫 번째 페이지부터 검색되도록
    setSearchQuery(newString || '');
  };

  // 정렬(order) 기능
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // 페이지 변경
  const handleChangePage = (event, newPage) => {
    getProductList(newPage);
    // setPage(newPage);
  };

  // 페이지당 표현할 열 수 변경
  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setRowsPerPage(event.target.value);
  };

  // 상품 수정 후 수정된 상품 최신 정보 불러오기
  const selectProductReload = async () => {
    const { _id: product_id } = selectedProduct;

    const productSnapshot = await getDoc(doc(getFirestore(), 'product', product_id)).catch((error) => ({ error }));

    if (productSnapshot.error) {
      console.error('[ProductList][selectProductReload] 상품정보 조회 오류 > ', productSnapshot.error);
    }

    const productData = productSnapshot.data();

    // 기존 데이터 포맷
    const { price, quantity } = productData;

    // 상품목록 index에 해당하는 상품정보 변경
    const newProducts = rows.products.map((product, index) => {
      if (index === selectedProductIndex) {
        return {
          ...product,
          price__original: price?.original,
          price__sale: price?.sale,
          quantity
        };
      }
      return product;
    });

    setRows({ loaded: true, products: newProducts });
  };

  // 상품 삭제 후 배열 재정렬
  const deleteProductReload = async (deleteRowId) => {
    // rows.products 배열에서 deleteRowId를 가지고 index 조회
    const deleteRowIndex = rows.products.findIndex((product) => product._id === deleteRowId);
    // 조회된 index를 삭제
    setRows({ loaded: true, products: rows.products.filter((product, index) => index !== deleteRowIndex) });
  };

  // render
  return (
    <Box>
      <Box>
        {/* Header */}
        <AppBar position="sticky">
          <Toolbar>
            <ToolbarTitle>상품 관리</ToolbarTitle>
          </Toolbar>
        </AppBar>

        {/* Contents */}
        <Container sx={{ py: '8px', mb: '8px' }}>
          <main>
            <Box>
              {/* 와인검색 */}
              <ProductSearchBar searchProductQry={(qry) => setSearchWord(qry)} />
            </Box>
          </main>
        </Container>
        <AppBar id="vendor-sticky-header" position="sticky" sx={{ top: 0, minHeight: '0px' }}>
          <Box>
            <Box borderBottom="1px solid #e5e5e5" px="20px">
              <TypeTabs
                value={category}
                onChange={(e, newValue) => {
                  setCategory(newValue);
                  setPage(1);
                }}
                variant="scrollable"
                indicatorColor="primary"
                textColor="inherit"
                aria-label="상품카테고리 탭"
              >
                <Tab label="전체" id="type-tab-all" value="all" />
                {PDATA_CATEGORIES.map(({ value, label }) => (
                  <Tab
                    key={`category-tab-${value.toLowerCase()}`}
                    label={label.ko}
                    id={`category-tab-${value.toLowerCase()}`}
                    value={value}
                  />
                ))}
              </TypeTabs>
            </Box>
          </Box>
        </AppBar>

        {/* 프로모션 상품만 보기 토글 버튼 영역 */}
        <Box p="15px 20px">
          <SpaceBetweenBox>
            <Typography component="span" fontSize="14px" color="#161719">
              프로모션 상품만 보기
            </Typography>
            <IOSSwitch checked={showPromo} onChange={(e) => setShowPromo(e.target.checked)} />
          </SpaceBetweenBox>
        </Box>

        <Box p="16px 0 13px 20px">
          <Stack direction="row" spacing="4px" alignItems="center">
            <Typography component="span" fontSize="14px" color="#161719">
              상품 수
            </Typography>
            {rows.loaded ? (
              <Typography component="span" fontSize="14px" color="#9357E5">
                {totalCounts.toLocaleString()}
              </Typography>
            ) : (
              <CircularProgress size={15} />
            )}
          </Stack>
        </Box>

        <Box pb="104px">
          {/* 상품 추가 버튼 */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              background: 'radial-gradient(#A36FEB, #9357E5)',
              borderRadius: '30px',
              padding: '15px 16px',
              bottom: '74px',
              right: '16px',
              position: 'fixed',
              zIndex: 1299,
              boxShadow: '0 3px 6px 2px #00000033'
            }}
            onClick={() => setOpenAddProduct(true)}
          >
            <PlusIcon />
            <Typography ml="8px" color="#ffffff" lineHeight={1}>
              상품 추가
            </Typography>
          </Box>

          {/* 상품 테이블 */}
          <TableContainer>
            <Table id="product-manage-table" size="small" aria-labelledby="상품 테이블">
              {/* 헤드 */}
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={headCell.align} sortDirection={orderBy === headCell.id ? order : false}>
                      <TableSortLabel
                        active={!headCell.disableOrder}
                        hideSortIcon={headCell.disableOrder}
                        disabled={headCell.disableOrder}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={() => {
                          handleRequestSort(headCell.id);
                        }}
                        sx={{
                          '& .MuiTableSortLabel-icon': {
                            color: `${orderBy === headCell.id ? theme.palette.primary[800] : theme.palette.grey[500]} !important`,
                            fontSize: '1rem'
                          }
                        }}
                      >
                        <Typography>{headCell.label}</Typography>
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {/* 바디 */}
              <TableBody>
                {/* 조회된 전체 상품 목록 */}
                {rows.loaded &&
                  !showPromo &&
                  rows.products.length > 0 &&
                  rows.products.map((row, index) => {
                    const { _id, promo_state, name__ko, vintage, capacity, price__original, quantity, category, promo_end_dt } = row;

                    const promEndDt = moment(promo_end_dt, 'YYYY-MM-DD HH:mm:ss');
                    const now = moment().format('YYYY-MM-DD HH:mm:ss');

                    return (
                      <TableRow
                        sx={{ backgroundColor: `${quantity === 0 ? '#FFFCFC' : '#FFFFFF'}` }}
                        key={`product-manage-table-${index}-row`}
                      >
                        {/* 상품명/빈티지/용량 */}
                        <TableCell
                          align="left"
                          onClick={() => {
                            setSelectedPid(_id);
                            if (category !== 'ticket') {
                              setOpenDetail(true);
                            }
                          }}
                        >
                          <Stack spacing="8px">
                            {/* 상품명 */}
                            <Typography className="line-2-ellipsis" fontWeight={600} fontSize="14px" color="#161719" lineHeight={1}>
                              {/* 프로모션 대기 칩 */}
                              {promo_state === 'WAIT' && (
                                <Typography component="span" fontSize="14px" fontWeight={700} color="#B296D4" mr="2px">
                                  [프로모션 대기]
                                </Typography>
                              )}
                              {/* 프로모션 진행 칩 */}
                              {promo_state === 'ING' && moment(now).isBefore(promEndDt) && (
                                <Typography component="span" fontSize="14px" fontWeight={700} color="#9357E5" mr="2px">
                                  [프로모션 진행]
                                </Typography>
                              )}
                              {name__ko ?? <Skeleton />}
                            </Typography>
                            <Stack
                              mt="8px"
                              direction="row"
                              spacing="4px"
                              divider={
                                <Box display="flex" alignItems="center" py="1px">
                                  <Box height={1} width="0.75px" bgcolor="#A2A2A3" />
                                </Box>
                              }
                            >
                              {/* 빈티지 */}
                              <Typography variant="caption" fontSize="12px" color="#A2A2A3" lineHeight={1}>
                                {vintage ?? <Skeleton width="28px" />}
                              </Typography>
                              {/* 용량 */}
                              <Typography variant="caption" fontSize="12px" color="#A2A2A3" lineHeight={1}>
                                {capacity ? bottleCapacityToString(capacity) : <Skeleton width="28px" />}
                              </Typography>
                            </Stack>
                          </Stack>
                        </TableCell>

                        {/* 가격 */}
                        <TableCell
                          align="right"
                          onClick={() => {
                            setSelectedProduct(row);
                            setSelectedProductIndex(index);
                            setOpenProductUpdateModal(true);
                          }}
                        >
                          <Box border={`1px solid ${quantity === 0 ? '#FC735C33' : '#0000001F'}`} borderRadius="5px" padding="6px 4px">
                            <Typography color="#130328" fontSize="14px">
                              {price__original?.toLocaleString('ko-KR') ?? <Skeleton />}
                            </Typography>
                          </Box>
                        </TableCell>

                        {/* 재고 */}
                        <TableCell
                          align="center"
                          onClick={() => {
                            setSelectedProduct(row);
                            setSelectedProductIndex(index);
                            setOpenProductUpdateModal(true);
                          }}
                        >
                          <Box border={`1px solid ${quantity === 0 ? '#FC735C33' : '#0000001F'}`} borderRadius="5px" padding="6px 4px">
                            <Typography color={quantity === 0 ? '#FC735C' : '#161719'} fontSize="14px">
                              {quantity?.toLocaleString() ?? <Skeleton />}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                {/* 조회된 프로모션 상품 목록 */}
                {rows.loaded &&
                  showPromo &&
                  rows.products.length > 0 &&
                  rows.products.map((row, index) => (
                    <TableRow
                      sx={{ backgroundColor: `${row.quantity === 0 ? '#FFFCFC' : '#FFFFFF'}` }}
                      key={`product-manage-table-${index}-row`}
                    >
                      {/* 상품명/빈티지/용량 */}
                      <TableCell
                        align="left"
                        onClick={() => {
                          setSelectedPid(row.product?._id);
                          setOpenDetail(true);
                        }}
                      >
                        <Stack spacing="8px">
                          {/* 상품명 */}
                          <Typography className="line-2-ellipsis" fontWeight={600} fontSize="14px" color="#161719" lineHeight={1}>
                            {/* 프로모션 대기 칩 */}
                            {row.promotion?.state === 'WAIT' && (
                              <Typography component="span" fontSize="14px" fontWeight={700} color="#B296D4" mr="2px">
                                [프로모션 대기]
                              </Typography>
                            )}
                            {/* 프로모션 진행 칩 */}
                            {row.promotion?.state === 'ING' && (
                              <Typography component="span" fontSize="14px" fontWeight={700} color="#9357E5" mr="2px">
                                [프로모션 진행]
                              </Typography>
                            )}
                            {row.product?.pdata?.name?.ko ?? <Skeleton />}
                          </Typography>
                          <Stack
                            mt="8px"
                            direction="row"
                            spacing="4px"
                            divider={
                              <Box display="flex" alignItems="center" py="1px">
                                <Box height={1} width="0.75px" bgcolor="#A2A2A3" />
                              </Box>
                            }
                          >
                            {/* 빈티지 */}
                            <Typography variant="caption" fontSize="12px" color="#A2A2A3" lineHeight={1}>
                              {row.product?.vintage ?? <Skeleton width="28px" />}
                            </Typography>
                            {/* 용량 */}
                            <Typography variant="caption" fontSize="12px" color="#A2A2A3" lineHeight={1}>
                              {row.product?.capacity ? bottleCapacityToString(row.product?.capacity) : <Skeleton width="28px" />}
                            </Typography>
                          </Stack>
                        </Stack>
                      </TableCell>

                      {/* 가격 */}
                      <TableCell
                        align="right"
                        onClick={() => {
                          setSelectedProduct(row);
                          setSelectedProductIndex(index);
                          setOpenProductUpdateModal(true);
                        }}
                      >
                        <Box border={`1px solid ${row.quantity === 0 ? '#FC735C33' : '#0000001F'}`} borderRadius="5px" padding="6px 4px">
                          <Typography color="#130328" fontSize="14px">
                            {row.price?.original?.toLocaleString('ko-KR') ?? <Skeleton />}
                          </Typography>
                        </Box>
                      </TableCell>

                      {/* 재고 */}
                      <TableCell
                        align="center"
                        onClick={() => {
                          setSelectedProduct(row);
                          setSelectedProductIndex(index);
                          setOpenProductUpdateModal(true);
                        }}
                      >
                        <Box border={`1px solid ${row.quantity === 0 ? '#FC735C33' : '#0000001F'}`} borderRadius="5px" padding="6px 4px">
                          <Typography color={row.quantity === 0 ? '#FC735C' : '#161719'} fontSize="14px">
                            {row.quantity?.toLocaleString() ?? <Skeleton />}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}

                {/* 조회 결과 없음 */}
                {rows.loaded && rows.products.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3} sx={{ textAlign: 'center', border: 'none', height: '6rem' }}>
                      상품 목록이 없습니다.
                    </TableCell>
                  </TableRow>
                )}

                {/* 상품목록 조회 중 */}
                {!rows.loaded && (
                  <TableRow>
                    <TableCell colSpan={3} sx={{ textAlign: 'center', border: 'none', height: '6rem' }}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* 테이블 페이징 */}
          {rows.loaded && rows.products.length > 0 && (
            <Grid container justifyContent="space-between" marginTop="1rem">
              <Grid item xs={8}>
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handleChangePage}
                  variant="outlined"
                  shape="rounded"
                  sx={{ padding: '12px' }}
                />
              </Grid>
              <Grid item xs={4} alignSelf="center" sx={{ textAlign: 'right' }}>
                <FormControl size="small" sx={{ pr: 2 }}>
                  <InputLabel>Row</InputLabel>
                  <Select label="Row" value={rowsPerPage} onChange={handleChangeRowsPerPage} sx={{ textAlign: 'center' }}>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}

          {/* 와인 상세 Dialog */}
          {openDetail && <ProductDetail open={openDetail} onClose={() => setOpenDetail(false)} pid={selectedPid} />}

          {/* 상품정보 수정 Dialog */}
          {openProductUpdateModal && (
            <ProductUpdate
              open={openProductUpdateModal}
              onClose={() => setOpenProductUpdateModal(false)}
              selectedProduct={selectedProduct}
              reloadTable={() => selectProductReload()}
              reloadArray={(deleteRowId) => deleteProductReload(deleteRowId)}
            />
          )}
        </Box>
      </Box>

      {/* 상품추가 모달 */}
      {openAddProduct && (
        <ProductCreate
          open={openAddProduct}
          onClose={() => {
            setOpenAddProduct(false);
          }}
        />
      )}
    </Box>
  );
};

export default ProductList;

// Toggle Button
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" checked={props.checked} onChange={props.onChange} disableRipple {...props} />
))(({ theme }) => ({
  width: 54,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(28px)',
      color: '#DEFD9A',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#220348',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    '&:before, &:after': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: '11px',
      fontFamily: palette_.font_family,
      fontWeight: '500'
    },
    '&:before': {
      content: '"ON"',
      color: '#fff',
      left: 7
    },
    '&:after': {
      content: '"OFF"',
      color: '#fff',
      right: 5
    },
    backgroundColor: theme.palette.mode === 'light' ? '#A19AA9' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

function PlusIcon() {
  return (
    <SvgIcon
      sx={{
        width: '16px',
        height: '16px',
        strokeWidth: '1.5',
        stroke: '#ffffff',
        fill: 'none',
        strokeLinecap: 'round',
        strokeLinejoin: 'round'
      }}
      viewBox="0 0 24 24"
    >
      <path style={{ stroke: 'none', fill: 'none' }} d="M0 0h24v24H0z" />
      <path d="M12 5l0 14" />
      <path d="M5 12l14 0" />
    </SvgIcon>
  );
}

const TypeTabs = styled(Tabs)(
  ({ theme }) => `
  height: auto;
  & .MuiTab-root {
    height: 54px;
    min-width: 42px;
    line-height: normal;
    font-size: 14px;    
    color: ${theme.palette.text.primary};
    opacity: 1;
    transition: all ${theme.transitions.duration.short} ${theme.transitions.easing.easeInOut};
    padding-left: ${theme.spacing(1)};
    padding-right: ${theme.spacing(1)};
    
    &.Mui-selected {
      font-weight: 800;
    }
    &.Mui-disabled {
      opacity: 0.35;
    }
  }
  
  & .MuiTabs-indicator {
    transition: none;
    background-color: #2d154d;
  }

`
);
