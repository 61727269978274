import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import moment from 'moment';

// project imports
import { USER_AGENT } from 'store/slices/wine-one';
import useAuth from 'hooks/useAuth';
import { useWineOne } from 'hooks';
import { WoAlert } from 'utils/kmwine-alerts';
import { waitingOrderCount } from 'services/OrderService';
import { Header } from './components/Header';
import ReqWait from './components/ReqWait';
import VisitWait from './components/VisitWait';
import { ResetIcon } from 'components/icons';
import SpaceBetweenBox from 'components/SpaceBetweenBox';
import { VendorInformation, StopInfo, CalculateInfo, ShopNotice, CoBuy } from './components';
import PromotionList from './components/Promotion/PromotionList';

// material-ui
import { Box, Container, Divider, Typography } from '@mui/material';

const { navigator } = window;
const { userAgent } = navigator;

/**
 * 홈 메인화면
 * @constructor
 *
 * @authors 이재일<leeji@wineone.io>
 */
function HomeIndex() {
  // 유저 정보
  const { user, logout } = useAuth();
  const { currentUser } = getAuth();
  const { onNativeBackPressed } = useWineOne();

  const navigate = useNavigate();

  // 뒤로가기
  const pageBackFunc = React.useCallback(() => {
    console.debug(`홈 화면에서 기기의 '뒤로가기' 버튼이 감지되었습니다.`);

    const isApplication = userAgent.includes(USER_AGENT.PREFIX);
    if (isApplication) {
      // 안드로이드 앱 종료
      if (userAgent.includes(USER_AGENT.AOS)) {
        finishApp();
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (window.history.length > 0) {
        navigate(-1);
      } else {
        navigate('/', { replace: true });
      }
    }
  }, []);

  React.useEffect(() => {
    // 기기에서 뒤로가기 버튼을 눌렀을 때
    onNativeBackPressed(pageBackFunc);
  }, [pageBackFunc]);

  // 앱 종료
  function finishApp() {
    console.log('앱 종료하기');
    // 구매확정 API요청
    WoAlert.fire({
      title: (
        <Typography component="div" fontSize={18} fontWeight="bold" textAlign="left">
          1KMWINE
        </Typography>
      ),
      html: `<div style='line-height: 1.5rem; text-align: left'>앱을 종료하시겠습니까?</div>`,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: '앱 종료',
      cancelButtonText: '취소',
      customClass: {
        confirmButton: 'max-50',
        cancelButton: 'max-50'
      }
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        try {
          window.kmwine.finishApp();
        } catch (e) {
          console.warn('안드로이드 앱 종료 실패.', e);
        }
      }
    });
  }

  // 주문 승인 대기, 매장 방문대기 카운트 정보
  const [waitCounts, setWaitCounts] = React.useState(null);

  // refresh icon shake
  const [shake, setShake] = React.useState(false);
  // 샵 임시 중지 상태
  const [vendorStopState, setVendorStopState] = React.useState(null);

  // 오늘 날짜 정보
  const [today, setToday] = React.useState(moment().format('YYYY년 MM월 DD일 (ddd요일)'));

  React.useEffect(() => {
    if (currentUser) {
      // 주문 승인 대기, 매장 방문 대기 카운트 조회
      getWaitingOrderCount();
    } else {
      logout();
    }
  }, [currentUser]);

  // 주문 승인 대기, 매장 방문 대기 카운트 조회
  const getWaitingOrderCount = async () => {
    const { vendorId = null } = user;
    if (!vendorId) {
      console.error('[홈] 입점샵 id가 존재하지 않습니다. 로그아웃 처리 됩니다.');
      logout();
      return false;
    }

    setWaitCounts(null);
    // --------------------------------------------------------------- 주문 승인 대기, 매장 방문 대기 카운트 조회 [START]
    // const waitCounts = await OrderService.waitingOrderCount().catch((error) => ({ error }));
    const waitCounts = await waitingOrderCount().catch((error) => ({ error }));

    // 주문 승인 대기, 매장 방문 대기 카운트 조회 중 오류 발생
    if (waitCounts.error) {
      console.error('[HomeIndex][getWaitingOrderCount] 주문 승인 대기, 매장 방문 대기 카운트 조회 중 오류 발생 > ', waitCounts.error);
      WoAlert.fire('', '주문 정보 조회 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요', 'error');
      return undefined;
    }

    const { success, results } = waitCounts;

    // 잘못된 Response 값이 넘어옴
    if (!success) {
      console.error('[홈] 주문 승인 대기, 매장 방문 대기 카운트 조회 중 오류 발생 > 잘못된 Response 값이 넘어옴');
      WoAlert.fire('', '주문 정보 조회 중 오류가 발생했습니다.<br />잠시 후 다시 시도해주세요', 'error');
      return undefined;
    }

    setWaitCounts(results);
  };

  // 새로고침 아이콘 클릭이벤트
  const refresh = async () => {
    setShake(true);
    setToday(moment().format('YYYY년 MM월 DD일 (ddd요일)'));
    await getWaitingOrderCount().finally(() => {
      setShake(false);
    });
  };

  // render
  return (
    <Box>
      <main>
        {/* 헤더 */}
        <Header />

        <Divider sx={{ mx: '20px' }} />

        <Container sx={{ bgcolor: '#F3F2F5' }}>
          {/* 입점샵 정보 */}
          <VendorInformation />

          {/* 높이계산 -> header 122px, divider 1px, vendorInformation 122px, footer 56px */}
          <Box sx={{ width: '100%', minHeight: 'calc(100vh - 54px - 1px - 122px - 56px)', pb: 2 }}>
            {/* 샵 영업 상태 */}
            <StopInfo waitCounts={waitCounts} vendorStopState={(val) => setVendorStopState(val)} />

            {/* 샵 정산 정보 */}
            <CalculateInfo />

            {/* 샵 공지사항 */}
            <ShopNotice />

            {/* 오늘 날짜 정보 / 새로고침 */}
            <SpaceBetweenBox sx={{ mt: '24px', mb: '12px' }}>
              <Typography sx={{ fontSize: '12px', color: '#333333CC', letterSpacing: '-0.12px' }}>{today}</Typography>
              <Box className={shake ? 'refresh_shake' : 'refresh'} width="22px" height="22px" onClick={() => refresh()}>
                <ResetIcon />
              </Box>
            </SpaceBetweenBox>

            <Divider color="#E9E7ED" />

            {/* 주문 승인 대기 */}
            <ReqWait waitCounts={waitCounts} vendorStopState={vendorStopState} />

            {/* 매장 방문 대기 */}
            <VisitWait waitCounts={waitCounts} />

            {/* 1KMWINE 프로모션 목록 */}
            <PromotionList />

            {/* 공동 구매 */}
            <CoBuy />
          </Box>
        </Container>
      </main>
    </Box>
  );
}

export default HomeIndex;
