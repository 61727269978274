import React, { useState } from 'react';

// project imports
import { getLatestTerm, TERM_TYPE, termTypeToString } from 'services/AppService';
import { BottomDialog, WoDialogTitle, WoDivider } from './dialog';

// material-ui
import { Box, Container, Grid, Typography, Stack, ButtonBase, DialogContent, Button } from '@mui/material';
import { ErrorIcon } from './icons';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

/**
 * 1KMWINE VENDOR APP footer
 * @constructor
 *
 * @author 이재일<leeji@wineone.io>
 */
function Footer() {
  // 약관 다이얼로그 열림여부
  const [openDialog, setOpenDialog] = useState(false);

  const initialTermState = {
    loaded: false,
    error: false,
    type: null,
    term: null
  };

  // 약관/정책
  const [termState, setTermState] = useState(initialTermState);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleTermDialog = (type) => {
    setOpenDialog(true);
    fetchTerm(type);
  };

  const fetchTerm = (type) => {
    setTermState({ ...initialTermState, type });

    getLatestTerm(type.toUpperCase())
      .then((snapshot) => {
        if (!snapshot.empty) {
          setTermState({ ...termState, type, loaded: true, error: false, term: snapshot.docs[0].data() });
        } else {
          console.error(`[1kmwine] 문서가 존재하지 않습니다. [type=${type}]`);
          setTermState({ ...termState, type, loaded: true, error: false, term: null });
        }
      })
      .catch((e) => {
        console.error('[1kmwine] 문서 조회 중 오류가 발생했습니다.', e);
        setTermState({ ...termState, type, loaded: true, error: true, term: null });
      });
  };

  return (
    <Box component="footer" sx={{ width: '100%', position: 'absolute', bottom: 0, left: 0 }}>
      <Grid container sx={{ minHeight: 97 }}>
        <Grid item xs={12} md={6} sx={{ bgcolor: '#FAFAFA', p: '16px' }}>
          <Typography variant="subtitle1" fontSize="12px" color="#130328">
            (주)WineOne Copyright ⓒ 2023 WineOne inc.
          </Typography>
          <Typography variant="subtitle1" fontSize="12px" color="#130328">
            All rights reserved
          </Typography>
          <Stack display="flex" direction="row" justifyContent="flex-start" spacing={1} divider={<Box>&middot;</Box>}>
            <TermButton
              docName={termTypeToString(TERM_TYPE.SERVICE)}
              onClick={() => {
                handleTermDialog(TERM_TYPE.SERVICE);
              }}
            />
            <TermButton
              docName={termTypeToString(TERM_TYPE.PERSONAL)}
              onClick={() => {
                handleTermDialog(TERM_TYPE.PERSONAL);
              }}
            />
          </Stack>
        </Grid>
      </Grid>

      <BottomDialog fullScreen open={openDialog} onClose={handleCloseDialog}>
        <WoDialogTitle title={termTypeToString(termState.type)} onClickClose={handleCloseDialog} />
        <WoDivider />
        <DialogContent sx={{ px: 0 }}>
          <Container maxWidth="xs" disableGutters>
            {termState.loaded && !termState.error && <div dangerouslySetInnerHTML={{ __html: termState.term.content }} />}
          </Container>
          {termState.error && (
            <Container>
              <Box py="72px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <ErrorIcon />
                <Typography sx={{ mt: '12px' }}>문서 조회 중 오류가 발생 했습니다</Typography>
                <Button variant="contained" color="brand" startIcon={<RefreshOutlinedIcon />} onClick={fetchTerm} sx={{ mt: '7px' }}>
                  다시 시도
                </Button>
              </Box>
            </Container>
          )}
        </DialogContent>
      </BottomDialog>
    </Box>
  );
}

export default Footer;

/* eslint-disable react/prop-types */
const TermButton = ({ docName, onClick }) => {
  return (
    <ButtonBase onClick={onClick} sx={{ fontSize: '11px', fontWeight: 'normal', lineHeight: 1.73, letterSpacing: '-0.11px' }}>
      {docName}
    </ButtonBase>
  );
};
